.progress {
  height: 8px;
  .box-shadow(none);
}

// Bar of progress
.progress-bar {
  line-height: 8px;
  .box-shadow(none);
}
