.btn-group .btn-default.active {
  background-color: @brand-primary;
  color: #fff;
  .box-shadow(none);
  border-color: transparent;

  &:hover,
  &:focus,
  &.focus {
    background-color: darken(@brand-primary, 10%);
  }

}
