/**
 * Based on (C)Leanest CSS spinner ever
 * http://lea.verou.me/2013/11/cleanest-css-spinner-ever/
**/

// private vars
@spinner-container: 4em;
@spinner-part-width: @spinner-container * .1;
@spinner-part-height: @spinner-container * .3;

@keyframes spin {
  to { transform: rotate(360deg); }
}

.small.spinner {
  font-size: @spinner-size / 2;
}

.large.spinner {
  font-size: @spinner-size * 1.5;
}

.spinner {
	position: relative;
	display: inline-block;
  margin-bottom: @line-height-base;
	width: @spinner-container;
	height: @spinner-container;
	font-size: @spinner-size;
	text-indent: 999em;
	overflow: hidden;
  animation: spin @spinner-speed infinite linear;
}

.spinner:before,
.spinner:after,
.spinner > div:before,
.spinner > div:after {
  content: '';
  position: absolute;
  top: 0;
  left: (@spinner-container - @spinner-part-width)/2; /* (container size - part width)/2  */
  width: @spinner-part-width;
  height: @spinner-part-height;
  background: @spinner-color;
  border-radius: .2em;
  transform-origin: 50% (@spinner-container/2); /* container height / 2 */
  box-shadow: 0 (@spinner-container - @spinner-part-height) @spinner-color; /* container size - part height */
}

.spinner:after {
  transform: rotate(-45deg);
}

.spinner > div:before {
	transform: rotate(-90deg);
}

.spinner > div:after {
	transform: rotate(-135deg);
}

// Button spinner

.button {
  &.spinner {
    font-size: 5px;
    float: left;
    margin: 2px 10px 0 0;
  }

  &.spinner + span {
    float:left;
  }

  &:before,
  &:after,
  > div:before,
  > div:after {
    background-color: #fff;
    box-shadow: 0 (@spinner-container - @spinner-part-height) #fff;
  }
}
