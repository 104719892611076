ul.tracker {
  display:table;
  width:100%;
  padding: 0;
  margin-bottom: @tracker-margin-bottom;
  height: 68px;

  li {
    display:table-cell;
    text-align:center;
    position: relative;

    .tracker-step {
      width: 36px;
      height: 36px;
      margin: 0 auto;
      text-align: center;
      background-color: @tracker-step-background;
      border: 3px solid @body-bg;
      border-radius: 30px;
      line-height: 30px;
      position: relative;
      color: @tracker-step-color;
      z-index: 1;
    }

    .tracker-label {
      position:absolute;
      left: 0;
      right: 0;
      margin-top: 0.5rem;
      color: @text-muted;
      font-size: @font-size-small;
    }

    &::after {
      content: '';
      position: absolute;
      width:100%;
      background-color: @gray-lighter;
      height: 5px;
      left: 0;
      top: 15px;
    }
  }

  .tracker-complete {

    .tracker-label {
      color: @text-color;
    }

    .tracker-step {
      background-color: @brand-success;
      position: relative;
    }

    .tracker-step svg {
      margin-bottom: -2px;
    }
  }

  .tracker-active {
    .tracker-label {
      color: @brand-primary;
    }

    .tracker-step {
      background-color: @brand-primary;
    }
  }
}

@media (max-width: @screen-xs) {
  ul.tracker .tracker-label {
    display: none;
  }
}

@media (max-width: @screen-sm) {
  ul.tracker .tracker-label {
    font-size: @font-size-small;
  }
}
