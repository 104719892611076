.alert {
  position: relative;
  padding-left: 45px;
  color: @text-color;

  .alert-link {
    color: @brand-primary;
  }
}


.alert svg {
  color: #fff;
  height: 100%;
  left: 0;
  padding: 10px;
  position: absolute;
  width: 36px;
  top: 0;
}

.alert-danger svg {
  background: @alert-danger-border;
}

.alert-success svg {
  background: @alert-success-border;
}

.alert-warning svg {
  background: @alert-warning-border;
}

.alert-info svg {
  background: @alert-info-border;
}
