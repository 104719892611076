.dropdown-menu {
  padding: 0.5rem 0;

  > li > a {
    padding: 0.25rem 1rem;
  }
}

.dropdown-header {
  display: block;
  padding: 0.25rem 1rem;
}
