.btn-default {
  &:hover,
  &:focus,
  &.focus {
    border-color: @brand-cox;
    color: @brand-cox;
    background-color: #fff;
  }
}

.btn .icon {
  margin-bottom: -2px;
}

.btn-default .icon,
.btn-primary .icon {
  width: 1rem;
}

.btn {
  border-radius: 6rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn:focus {
  border-color: transparent;
}

.btn-circle {
  padding: 0;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.btn-lg.btn-circle {
  width: 48px;
  height: 48px;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: -5px;
  }
}
