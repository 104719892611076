.table-row-variant(active; @brand-info);
.table-row-variant(success; @brand-success);
.table-row-variant(info; @brand-info);
.table-row-variant(warning; @brand-warning);
.table-row-variant(danger; @brand-danger);

.table>thead>tr>th,
th {
  font-weight: normal;
  color: @text-muted;
  border-bottom: none;
}

table {
  font-size: 0.875rem; // 14px
}
