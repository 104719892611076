@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700|Roboto+Condensed);


html {
  font-size: @font-size-base;
}

body {
  font-weight: @font-weight-base;
  letter-spacing: @letter-spacing-base;
}

small,
.small {
  font-size: @font-size-small;
}

.page-header {
  border-bottom: none;
}

h1.display-1,
h1.display-2,
h1.display-3 {
  font-weight: 100;
}

h1.display-1 {
  font-size: @font-size-display-1;
}

h1.display-2 {
  font-size: @font-size-display-2;
}

h1.display-3 {
  font-size: @font-size-display-3;
}

.display-4 {
  color: @headings-color;
  font-size: @font-size-display-4;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}

dt, kbd kbd, label,
b, optgroup, strong {
    font-weight: 500;
}


// colored text overrides
.text-primary,
.text-info {
  color: @brand-primary;
}

.text-success {
  color: @brand-success;
}

.text-warning {
  color: @brand-warning;
}
.text-danger {
  color: @brand-danger;
}

// lead overrides
.lead {
  font-size: @lead-font-size;
  font-weight: @lead-font-weight;
  line-height: @lead-line-height;
  letter-spacing: @lead-letter-spacing;

  @media (min-width: @screen-sm-min) {
    font-size: @lead-font-size-sm
  }
}

// improve list readability
.list-spaced li {
  margin-bottom: 3px;
}

.list-bordered li {
  border-top: 1px solid @gray-light;
}
.list-bordered li:last-child {
  border-bottom: 1px solid @gray-light;
}

// serif family helper
.text-serif {
  font-family: @font-family-serif;
}

// responsive text helpers
.text-xs-left   { text-align: left; }
.text-xs-right  { text-align: right; }
.text-xs-center { text-align: center; }

@media (min-width: @screen-sm-min) {
  .text-sm-left   { text-align: left; }
  .text-sm-right  { text-align: right; }
  .text-sm-center { text-align: center; }
}

@media (min-width: @screen-md-min) {
  .text-md-left   { text-align: left; }
  .text-md-right  { text-align: right; }
  .text-md-center { text-align: center; }
}

@media (min-width: @screen-lg-min) {
  .text-lg-left   { text-align: left; }
  .text-lg-right  { text-align: right; }
  .text-lg-center { text-align: center; }
}
