.form-control {
  .box-shadow(none);
  font-size: 0.875rem; // 14px

  input,
  select,
  textarea {
    color: @text-color;
  }

  &[disabled] {
    background-color: #f8f8f8;
    color: rgba(0,0,0,0.35);
  }
}

.radio,
.checkbox {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

label {
  margin-bottom: 0.25rem;
}

.help-block {
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  .box-shadow(none);
  font-size: 0.875rem; // 14px
  color: @text-muted;
}

label {
  font-weight: normal;
  color: @text-muted;
  margin-top: 2px;
  font-size: 0.875rem; // 14px
}

legend {
  font-size: @font-size-base;
  font-weight: 400;
  color: @headings-color;
  border-bottom: none;
}

fieldset {
  margin-bottom: (@spacer-y * .83);
}

fieldset:first-child {
  margin-top: 0;
}

.form-horizontal .help-block {
  margin-bottom: 0;
}

.has-warning .help-block {
  color: @text-muted;
}

.alert-field {
  position: relative;
}

.alert-field input {
  padding-right: 30px;
}

.alert-field .icon {
  position: absolute;
  right: 10px;
  top: 11px;
}

.has-error .alert-field .icon {
  color: @brand-danger;
}

.has-warning .alert-field .icon {
  color: @brand-warning;
}

.has-success .alert-field .icon {
  color: @brand-success;
}

.error-block {
  .help-block;
  color: @brand-danger;
  margin-bottom: 0;
}

.form-inline .form-group,
.form-inline .checkbox,
.form-inline .radio {
  margin-right: .5rem;
}
